import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../../store/redux_store";


export const postnewUserdata = createAsyncThunk(
    'users/fetchNewUserdataintoDb',
    async (_, {getState, signal }) => {
      const source = axios.CancelToken.source()
      signal.addEventListener('abort', () => {
        source.cancel()
      })
  
      const state = getState() as RootState;
        const response = await axios.post('contactformular/addnewcontact', state.newContactStore.data.userOrderRequirements).then((response) => {
            console.log(response.status, response.data.token)
    });
      return response
    }
  )

  export const postEmailToCostumer = createAsyncThunk(
    'user/fetchEmail'
  ,async (_, {getState, signal }) => {
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })

    const state = getState() as RootState;
      const response = await axios.post('contactformular/sendcontactemail', state.newContactStore.data.userOrderRequirements).then((response) => {
          console.log(response.status, response.data.token,"emailsendapi")
  });
    return response
  })