import { ContactModelOverviewState, ErrorHandlerEnum } from "./contactmodel";
import {  createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postEmailToCostumer, postnewUserdata} from './contactthunks';
const initialContactState : ContactModelOverviewState={

    command:{
         contactRequest: {status: "idle", canExecute : false},
         contactRequestMail: {status:"idle", canExecute: false}
    },
    data:{
        contactInformations : {
            description :'',
            name:'',
            surName:'',
            companyName:'',
            email:''
        },
        userOrderRequirements:{
            contactData:{
                description :'',
            name:'',
            surName:'',
            companyName:'',
            email:''
            },
            orderTyp: 0,
            orderVarianteWebsite:0,
            orderVarianteAdvertsingEnum:0,
        },
        successDialoge:false,
        errorDialoe:false,
       

    },
    errorHandling:{
        errorStatusCompanyName:ErrorHandlerEnum.Undefine,
        errorStatusEmail:ErrorHandlerEnum.Undefine,
        errorStatusNachname:ErrorHandlerEnum.Undefine,
        errorStatusName:ErrorHandlerEnum.Undefine,
    }
}

export const contactCreateSlice = createSlice({
name:'contact',
initialState : initialContactState,
reducers:{
    resetState:(state)=> {
        state.data = initialContactState.data;
        state.command= initialContactState.command;
      
    },
    setInputName: (state, action: PayloadAction<string> )=> {

        state.data.userOrderRequirements.contactData.name= action.payload;

        if(state.data.userOrderRequirements.contactData.name.length>0){
            state.errorHandling.errorStatusName=ErrorHandlerEnum.Filled;
        }

        if(state.data.userOrderRequirements.contactData.name === ""){
            state.errorHandling.errorStatusName=ErrorHandlerEnum.Error;
        }
    },
    setInputSurName: (state, action: PayloadAction<string> )=> {

        state.data.userOrderRequirements.contactData.surName= action.payload;

        if(state.data.userOrderRequirements.contactData.surName.length>0){
            state.errorHandling.errorStatusNachname=ErrorHandlerEnum.Filled;
        }

        if(state.data.userOrderRequirements.contactData.surName ===""){
            state.errorHandling.errorStatusNachname=ErrorHandlerEnum.Error;
        }
    },
    setInputEmail: (state, action: PayloadAction<string> )=> {

        state.data.userOrderRequirements.contactData.email= action.payload;

        if (state.data.userOrderRequirements.contactData.email.length > 0) {

            if (state.data.userOrderRequirements.contactData.email.includes('@')) {


                state.errorHandling.errorStatusEmail = ErrorHandlerEnum.Filled;
            } else {
                state.errorHandling.errorStatusEmail = ErrorHandlerEnum.Error;
            }
          
        }

        if(state.data.userOrderRequirements.contactData.email === ""){
            state.errorHandling.errorStatusEmail=ErrorHandlerEnum.Error;
        }
    },
    setInputCompanyName: (state, action: PayloadAction<string> )=> {

        state.data.userOrderRequirements.contactData.companyName= action.payload;

        if(state.data.userOrderRequirements.contactData.companyName.length>0 ){

            state.errorHandling.errorStatusCompanyName=ErrorHandlerEnum.Filled;
        }

        if(state.data.userOrderRequirements.contactData.companyName === "" ){

            state.errorHandling.errorStatusCompanyName=ErrorHandlerEnum.Error;
         }
        
    },
    setInputDescription: (state, action: PayloadAction<string> )=> {

        state.data.userOrderRequirements.contactData.description= action.payload;
    },
    setContactData:(state )=> {
       /* state.data.userOrderRequirements.contactData = state.data.contactInformations;*/
    },
    setOrderTyp:(state, action: PayloadAction<number> )=> {
        state.data.userOrderRequirements.orderTyp= action.payload;
    },
    setOrderVarianteWebsite:(state, action: PayloadAction<number> )=> {
        state.data.userOrderRequirements.orderVarianteWebsite= action.payload;
    },
    setOrderVarianteAdvertsingEnum:(state, action: PayloadAction<number> )=> {
        state.data.userOrderRequirements.orderVarianteAdvertsingEnum= action.payload;
    },
    setSuccessDialogeTofalse:(state,  action: PayloadAction<boolean> )=> {
            state.data.successDialoge = action.payload;
    },
    checkIfAllInputFieldWasFilled :(state)=> {

        //set Error False
        if(state.data.userOrderRequirements.contactData.companyName.length>0 ){

           state.errorHandling.errorStatusCompanyName=ErrorHandlerEnum.Filled;
        }

        if (state.data.userOrderRequirements.contactData.email.length > 0) {
            if (state.data.userOrderRequirements.contactData.email.includes('@')) {
                state.errorHandling.errorStatusEmail = ErrorHandlerEnum.Filled;
            } else {
                state.errorHandling.errorStatusEmail = ErrorHandlerEnum.Error;
            }
           
        }

        if(state.data.userOrderRequirements.contactData.name.length>0){
            state.errorHandling.errorStatusName=ErrorHandlerEnum.Filled;
        }

        if(state.data.userOrderRequirements.contactData.surName.length>0){
            state.errorHandling.errorStatusNachname=ErrorHandlerEnum.Filled;
        }

        //set Error True
        if(state.data.userOrderRequirements.contactData.companyName === "" ){

            state.errorHandling.errorStatusCompanyName=ErrorHandlerEnum.Error;
         }
 
         if(state.data.userOrderRequirements.contactData.email === ""){
             state.errorHandling.errorStatusEmail=ErrorHandlerEnum.Error;
         }
 
         if(state.data.userOrderRequirements.contactData.name === ""){
             state.errorHandling.errorStatusName=ErrorHandlerEnum.Error;
         }
 
         if(state.data.userOrderRequirements.contactData.surName ===""){
             state.errorHandling.errorStatusNachname=ErrorHandlerEnum.Error;
         }
    }
}, extraReducers: (builder)=> {
    builder.addCase(postnewUserdata.pending, (state)=> {
        state.command.contactRequest.status ="pending";
        state.command.contactRequest.canExecute =false; 
    }).addCase(postnewUserdata.rejected, (state)=>{
        state.command.contactRequest.status ="error";
        state.command.contactRequest.canExecute =false; 
    }).addCase(postnewUserdata.fulfilled, (state,action)=> {
        state.command.contactRequest.status ="success";
        state.command.contactRequest.canExecute =true; 
        state.data.successDialoge = true;
        
    }).addCase(postEmailToCostumer.pending, (state)=> {
    state.command.contactRequestMail.status="pending";
    state.command.contactRequestMail.canExecute=false;
    }).addCase(postEmailToCostumer.rejected, (state)=> {
        state.command.contactRequestMail.status="error";
        state.command.contactRequestMail.canExecute=false;
    }).addCase(postEmailToCostumer.fulfilled, (state, action)=> {
        state.command.contactRequestMail.status="success";
        state.command.contactRequestMail.canExecute= true;
    })
}
})

export const {
    resetState,
    setInputName,
    setInputDescription,
    setInputCompanyName,
    setInputEmail,
    setInputSurName,
    setOrderTyp,
    setOrderVarianteWebsite,
    setOrderVarianteAdvertsingEnum,
    setSuccessDialogeTofalse,
    checkIfAllInputFieldWasFilled
} = contactCreateSlice.actions;
export const contactReducer = contactCreateSlice.reducer;