import React, { useState } from 'react'
import '../styles/contactFormular.css'
import '../styles/contactFormularMobiledevice.css'
import { CircularProgress, Stack, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { AppDispatch } from '../../store/redux_store';

import { checkIfAllInputFieldWasFilled, setInputCompanyName, setInputEmail, setInputName, setInputSurName, setOrderTyp, setOrderVarianteAdvertsingEnum, setOrderVarianteWebsite } from './redux/contactslice';


import { postEmailToCostumer, postnewUserdata } from './redux/contactthunks';


import { DialogeWindow } from './DialogeWindow/dialogeWindow';
import { ErrorHandlerEnum } from './redux/contactmodel';

export const ContactFormularOverview = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.newContactStore);
    const [status, setStatus] = useState(false);

    if (state.command.contactRequest.status === 'error') {
        return (<>

            <p>Ein Fehler ist aufgetreten schreiben Sie bitte Ihre Anfrage per email
                <a href='mailto:travis@getyourownwebsite.de'>travis@getyourownwebsite.de</a>
                   Wir entschuldigen uns für diese unangenehmlichkeit 
            </p>
        </>)
    }

    if(state.command.contactRequestMail.status==='error'){

      console.log("Email error")

    }

    if(state.command.contactRequest.status ==='pending'){

      //setloading(true);
      return( 
        <>
        <div className='pendingstatus' >
          <h2>Wir bearbeiten gerade Ihre Anfrage</h2>
          <p>Bitte haben Sie ein wenig geduld</p>
              <Stack alignItems="center">
                  <CircularProgress size={5 * 15}   />
              </Stack>
        </div>
        </>
      
      )
      
    }

    if(state.command.contactRequest.status ==='success')
    {
    
    }

    const SetValuesToZero= async ()=> {
      await dispatch(setOrderTyp(0));
      await dispatch(setOrderVarianteAdvertsingEnum(0));
      await dispatch(setOrderVarianteWebsite(0));
    }

    const SendContactToApi =  async () => {
       await dispatch(checkIfAllInputFieldWasFilled());
        //todo enum undefined Error Filled
        if (state.errorHandling.errorStatusCompanyName===ErrorHandlerEnum.Error || 
            state.errorHandling.errorStatusEmail ===ErrorHandlerEnum.Error ||
            state.errorHandling.errorStatusNachname ===ErrorHandlerEnum.Error || 
            state.errorHandling.errorStatusName===ErrorHandlerEnum.Error) {
            
            console.log(true, "User hat ein Eingabefeld vergessen")
            setStatus(false);
            
        }else if (state.errorHandling.errorStatusCompanyName===ErrorHandlerEnum.Undefine || 
          state.errorHandling.errorStatusEmail ===ErrorHandlerEnum.Undefine ||
          state.errorHandling.errorStatusNachname ===ErrorHandlerEnum.Undefine || 
          state.errorHandling.errorStatusName===ErrorHandlerEnum.Undefine){

            await dispatch(checkIfAllInputFieldWasFilled());

            if (state.errorHandling.errorStatusCompanyName===ErrorHandlerEnum.Filled || 
              state.errorHandling.errorStatusEmail ===ErrorHandlerEnum.Filled ||
              state.errorHandling.errorStatusNachname ===ErrorHandlerEnum.Filled || 
              state.errorHandling.errorStatusName===ErrorHandlerEnum.Filled) {
              
                console.log(false,"User Feld war Undefinded und wert wurde erneut berechnet => User hat alle Felder ausgefüllt")
                setStatus(true);
          }else{
            console.log(true, "User Feld war Undefinded und wert berechner ergebnis => User hat ein Eingabefeld vergessen")
            setStatus(false);
          }

        }else {
            console.log(false,"Alle Eingabefehler sind ausgefüllt Api wird versucht anzusprechen")
            await dispatch(postnewUserdata());
            await dispatch(postEmailToCostumer())
            await SetValuesToZero();
            setStatus(true);
        }
    }

  return (
    <>
    {state.data.successDialoge && status ? <DialogeWindow/> : <></>}
   
    <div className="contact-container">
  <div className="left-col">
   
  </div>
  <div className="right-col">
   
    


    
    <h1>Kontaktieren Sie uns</h1>
    <p className="secondHeadlineContact">Gerne Planen , wir ihre neue Website</p>
    <p className="requirmentfield">* Pflichtfelder</p>
    
    <div id="contact-form" >
   
  <TextField 
  required 
  error={state.errorHandling.errorStatusName===ErrorHandlerEnum.Error} 
  className="inputData" 
  label={state.errorHandling.errorStatusName ===ErrorHandlerEnum.Error ? "Error Name" : "Name"} 
  variant="outlined" 
  fullWidth 
  onChange={(e)=> dispatch(setInputName(e.target.value))} />

 <br/>
 <br/>
  <TextField 
  required 
  error={state.errorHandling.errorStatusNachname===ErrorHandlerEnum.Error} 
  className="inputData" 
  label={state.errorHandling.errorStatusNachname ===ErrorHandlerEnum.Error ? "Error Nachname":"Nachname"} 
  variant="outlined" 
  fullWidth 
  onChange={(e)=> dispatch(setInputSurName(e.target.value))} />
  <br/>
  <br/>
  <TextField 
  required 
  error={state.errorHandling.errorStatusCompanyName===ErrorHandlerEnum.Error} 
  className="inputData" 
  label={state.errorHandling.errorStatusCompanyName ===ErrorHandlerEnum.Error ? "Error Firmenname":"Firmenname"} 
  variant="outlined" 
  fullWidth 
  onChange={(e)=> dispatch(setInputCompanyName(e.target.value))} />
  
  <br/>
  <br/>
  <TextField 
  required error={state.errorHandling.errorStatusEmail===ErrorHandlerEnum.Error} 
  className="inputData"
  label={state.errorHandling.errorStatusEmail ===ErrorHandlerEnum.Error ? "Error Email":"Email"} 
  variant="outlined" 
  fullWidth 
  onChange={(e)=> dispatch(setInputEmail(e.target.value))} />
  <br/>
  <br/>

                      <button type="submit" id="submit" onClick={() => SendContactToApi()} name="submit">Send</button>
  
</div>
<div id="error"></div>
<div id="success-msg"></div>
  </div>
</div>
    </>
  )
}

export default ContactFormularOverview;


