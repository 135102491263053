import React from 'react'

export const ImpresumOverview = () => {
  return (
    <>
    <h1>Impressum</h1>

<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
<p>Travis Amadasun<br />
Unterer Markt 12<br />
95469 Speichersdorf<br /></p>

<h2>Kontakt</h2>
<p>Handy: +49 175 2138968<br />

E-Mail: travis@getyourownwebsite.de</p>

<p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
    </>
  )
}
