import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from "./store/redux_store";
import {  RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPageOverview from './admin/errorPage/errorpage';
import WebsiteOverviewComponent from './website/component/websiteOverview.Component';
import AdvertsingOverviewComponent from './advertising/component/advertsingoverviewcomponent';
import HomeOverviewComponent from './home/component/homeoverview.component';
import ContactFormularOverview from './website/component/contactFormular.Component';
import { ImpresumOverview } from './impressum/impresumOverview';
import { DatenschutzOverview } from './datenschutz/datenschutzOverview';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeOverviewComponent/>,
    errorElement: <ErrorPageOverview/>,
    },
    {
        path:"/website",
        element: <WebsiteOverviewComponent />,
        errorElement: <ErrorPageOverview />,
    },
    {
          path:"/advertising",
          element: <AdvertsingOverviewComponent />,
        errorElement: <ErrorPageOverview />,
    },
    {
      path:"/contactFormular",
      element: <ContactFormularOverview/>,
      errorElement: <ErrorPageOverview />,
    },
    {
      path:"/impressum",
      element: <ImpresumOverview/>,
      errorElement: <ErrorPageOverview />,
    },
    {
      path:"/datenschutz",
      element: <DatenschutzOverview/>,
      errorElement: <ErrorPageOverview />,
    }
]);


root.render(
  <React.StrictMode>
      <Provider store={store}>
            <RouterProvider router={router} />
      
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
