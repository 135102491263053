import { configureStore } from '@reduxjs/toolkit';
//import { counterReducer } from '../admin/counter/redux/counter.slices';
import { testingReducer } from '../admin/testing/redux/testing.slice';
import {contactReducer} from '../../src/website/component/redux/contactslice';

 const store = configureStore({
    reducer:{
        
        testingStore: testingReducer,
        newContactStore : contactReducer
        //countStore: counterReducer,
    },
});

export type RootState= ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;