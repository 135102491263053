import { LoadingState } from "../../../loading_state";




export interface IContactFormular{
    name: string,
    surName:string,
    email: string,
    companyName:string,
    description: string
}



export interface ContactModelOverviewState{
    
    command: {
            contactRequest: LoadingState,
            contactRequestMail: LoadingState
           
    };

    data:{
        contactInformations: IContactFormular,
        userOrderRequirements: IUserReQuirements,
        successDialoge : boolean;
        errorDialoe:boolean;
        
    };
    errorHandling:{
        errorStatusName:ErrorHandlerEnum;
        errorStatusNachname:ErrorHandlerEnum;
        errorStatusEmail:ErrorHandlerEnum;
        errorStatusCompanyName :ErrorHandlerEnum;
    }
}

export interface IUserReQuirements{

    contactData : IContactFormular,
    orderTyp: WebsiteOrAdvertsingEnum,
    orderVarianteWebsite: WebsiteEnum,
    orderVarianteAdvertsingEnum: AdvertsingEnum,
}



export enum WebsiteOrAdvertsingEnum{
    None=0,
    Website=1,
    Advertsing=2,
}

export enum WebsiteEnum{
    None=0,
    Spa=1,
    AspNet=2,
}
export enum AdvertsingEnum{
    None=0,
    Facebook=1,
    Instagram = 2,
    GoogleAds= 3
}


export enum ErrorHandlerEnum{
    Undefine=0,
    Error=1,
    Filled=2
}

