import React from 'react'
import { Carousel } from 'react-bootstrap';
import MainOverviewPicture from '../../images/MainPicture.png';
import WebsiteOverviewPicture from '../../images/WebsitePicture.png';
import WerbungOverviewPicture from '../../images/WerbungPictures.png';
import AnalyticsOverviewPicture from '../../images/Analytics.png';

export const CarouselOverview = () => {
  return (
    <>
    <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={MainOverviewPicture}
                        alt="First slide"
                    />
                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={WebsiteOverviewPicture}
                        alt="Second slide"
                    />

                  
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={WerbungOverviewPicture}
                        alt="Second slide"
                    />

                  
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={AnalyticsOverviewPicture}
                        alt="Second slide"
                    />

                  
                </Carousel.Item>
                
            </Carousel>
    </>
  )
}

export default CarouselOverview;