import React from 'react'
import { Link } from 'react-router-dom';

export const Footeroverviewcomponent = () => {
  return (
    <>
    <footer className="text-center text-lg-start bg-dark text-white ">
 
  <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">

    <div className="me-5 d-none d-lg-block">
      <span>Get connected with us on social networks:</span>
    </div>
    

   
    <div>
      <a href="https://www.facebook.com/people/Getyourownwebsite/100091300442770/" className="me-4 link-secondary">
        <i className="bi bi-facebook"></i>
      </a>
     
      {/* <a href="" className="me-4 link-secondary">
        <i className="bi bi-google"></i>
      </a>
      <a href="" className="me-4 link-secondary">
        <i className="bi bi-instagram"></i>
      </a>
      <a href="" className="me-4 link-secondary">
        <i className="bi bi-github"></i>
      </a> */}
    </div>
   
  </section>


  <section className="">
    <div className="container text-center text-md-start mt-5">
      
      <div className="row mt-3">
       
        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
       
          <h6 className="text-uppercase fw-bold mb-4">
            <i className="fas fa-gem me-3 text-secondary"></i>getyourownwebsite
          </h6>
          <p>
            Zukunft durch Digitalierung <br/>
            Arbeiten Sie heute noch mit uns <br/>
            um digital gut Aufgebaut zu sein
          </p>
        </div>
       

        
        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
        
          <h6 className="text-uppercase fw-bold mb-4">
            Produkte
          </h6>
          <p>
            <a href="#!" className="text-reset">Websites</a>
          </p>
          <p>
            <a href="#!" className="text-reset">Werbung</a>
          </p>
          <p>
            <a href="#!" className="text-reset">Auszeichnungen</a>
          </p>
         
        </div>
   
        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
         
          <h6 className="text-uppercase fw-bold mb-4">
            Rechtlinien
          </h6>
          <p>
            <a href="#!" className="text-reset">About us</a>
          </p>
          <p>
            <Link  to='/impressum' className="text-reset">Impressum</Link>
          </p>
          <p>
            <Link  to='/datenschutz' className="text-reset">Datenschutz</Link>
          </p>
          <p>
            <a href="#!" className="text-reset">FAQ</a>
          </p>
        </div>
      
        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
         
          <h6 className="text-uppercase fw-bold mb-4">Kontakte</h6>
          <p><i className="fas fa-home me-3 text-secondary"></i> Deutschland </p>
           <p>
            <i className="fas fa-envelope me-3 text-secondary"></i>
            95469 Speichersdorf
          </p>
         
          <a href='mailto:travis@getyourownwebsite.de'>
            <i className="fas fa-envelope me-3 text-secondary"></i>
             travis@getyourownwebsite.de
          </a>
          <br/>
          <a href='tel:+491752138968'><i className="fas fa-phone me-3 text-secondary"></i> +49 175 2138968</a>
        </div>
       
      </div>
   
    </div>
  </section>
 

 
  <div className="text-center p-4">
   Made by
    <a className="text-reset fw-bold" href='https://getyourownwebsite.de'>getyourownwebsite</a>
  </div>
 
</footer>
    </>
  )
}

export default Footeroverviewcomponent;