import { Link } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import './navigation.css';

export const NavigationComponent = () => {
    return (
        <>
        




            <nav className="navbar navbar-dark bg-dark">
                <div className="container-fluid">

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Link  to='/' className="navbar-brand" ><span className="getcoloryellow">G</span>et<span className="getcolorblue">Y</span>our<span className="getcoloryellow">O</span>wn<span className="getcolorblue">W</span>ebsite</Link>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" to='/'>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to='/website'>Website</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link active" to='/advertising'>Werbung</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}