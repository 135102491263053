import React from 'react'
import { NavigationComponent } from '../../admin/Navigation/navigation.component';

import { Footeroverviewcomponent } from '../../admin/footer/footeroverviewcomponent';

import fitnesstudioimages from '../../images/fittnessstudioimages.png'
import CardWerbung from '../../images/CardWerbung.jpg'
import CardWebsite from '../../images/CardWebsite.jpg'
import './homestyle.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';

import CarouselOverview from '../../admin/carousel/carouseloverviewComponent';
import { Link } from 'react-router-dom';
export  const HomeOverviewComponent =() =>{
  return (
    <>
    <NavigationComponent />
            <CarouselOverview/>


{/* <h3>Ihr Unternehmen verdient die beste Website</h3> */}
<div className='headline_main'>
<h2 className="headline_Text-h1 ">Responsive Webseiten</h2>
</div>
<div className="body_sectioncenter">

    <div className="row featurette ">
      <div className="col-md-7">
        
        <p className="lead">Wir bauen Webseiten für unterschiedliche Endgeräten, um eine optimale Benutzererfahrung auf verschiedenen Geräten wie Desktop-Computern, Laptops, Tablets und Smartphones zu gewährleisten. </p>
        <p className="lead">Eine Responsive Webseite ist so gestaltet, dass sie automatisch auf die Bildschirmgröße und Auflösung des Geräts reagiert, auf dem sie angezeigt wird. Das bedeutet, dass die Webseite unabhängig von der Größe des Bildschirms immer gut lesbar und navigierbar ist. </p>
        <p className="lead">Außerdem erhöht eine Responsive Webseite auch die Sichtbarkeit in Suchmaschinen, da Google und andere Suchmaschinen mobilfreundliche Webseiten bevorzugen und sie in ihren Suchergebnissen bevorzugt anzeigen. Eine Webseite, die nicht für Mobilgeräte optimiert ist, kann hingegen von Benutzern als unattraktiv oder schwer zu navigieren empfunden werden, was zu einer schlechten Benutzererfahrung führen kann und potenzielle Kunden verlieren könnte.</p>
      </div>
      <div className="col-md-5">
        <img src={fitnesstudioimages} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"  alt="right_pic01"/>
      </div>
    </div>
  </div>

  <div className="container-fluid">
  <div className="card-group">
            <div className="card">
                <img src={CardWebsite} className="card-img-top" alt="..."/>
                <div className="card-body">
                    <h5 className="card-title">Webseiten</h5>
                    <p className="card-text">Bei uns könnten sie zwischen eine Single Page Application (SPA) Website oder eine einer ASP.NET Web API Website mit React im Frontend wählen.  </p>
                     
                     <Link to='/website' className='btn btn-secondary'> zur Websiteseite</Link>
                </div>
            </div>
            <div className="card">
                <img src={CardWerbung} className="card-img-top" alt="..."/>
                <div className="card-body">
                    <h5 className="card-title">Werbungsschalten</h5>
                    <p className="card-text">Wir bieten die Möglichkeit Facebook Ads oder Google Ads für Sie zuschalten </p>
                   
                    <Link to='/advertising' className='btn btn-third'>zur Werbungseite</Link>
                </div>
            </div>
           </div>
           </div>

<br/>
<Footeroverviewcomponent/>
    </>
  )
}

export default HomeOverviewComponent;