import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react'
import '../../styles/successdialoge.css';
//import Button from '@mui/material/Button';
import { AppDispatch } from '../../../store/redux_store';
import { useAppDispatch } from '../../../store/hook';
import { setSuccessDialogeTofalse } from '../redux/contactslice';
import { useNavigate } from 'react-router-dom';
export const DialogeWindow = () => {
    const [open, setOpen] = React.useState(true);
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const handleClose = async () => {
        await setOpen(false);
        await dispatch(setSuccessDialogeTofalse(false));
        await navigate("/");
    };
    return (
        <>
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Erfolgreiche Anmeldung"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ihre Anmeldung war erfolgreich. <br/> <br/> Wir bedanken uns für Ihre Kontakt aufnahme
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    
                    <button className='btn btn-success' onClick={handleClose} autoFocus>
                        Ok
                    </button>
                </DialogActions>
            </Dialog>
        </div>
        </>)
}


