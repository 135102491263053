import {  useNavigate } from "react-router-dom";
import { NavigationComponent } from "../../admin/Navigation/navigation.component";
import CarouselOverview from "../../admin/carousel/carouseloverviewComponent";
import Footeroverviewcomponent from "../../admin/footer/footeroverviewcomponent";
import FitnessStudioimages from '../../images/fittnessstudioimages.png';
import mainlittle from '../../images/mainpicturesmall.png';
import  '../styles/websiteOverview.css';
import { setOrderTyp, setOrderVarianteWebsite } from "./redux/contactslice";
import { AppDispatch } from "../../store/redux_store";
import { useAppDispatch } from "../../store/hook";
import SinglePageApplicationPic from "../../images/React.png";
import Webapi from "../../images/Api Web Api.png"
export  const WebsiteOverviewComponent =() =>{
  const navigate = useNavigate();
  const dispatch: AppDispatch=useAppDispatch();


  const goToContactFormuularStandard=()=>{

    dispatch(setOrderTyp(1));
dispatch(setOrderVarianteWebsite(1));
   navigate("/contactFormular");
  }

  const goToContactFormuularDeluxe=()=>{
    dispatch(setOrderTyp(1));
    dispatch(setOrderVarianteWebsite(2));
    navigate("/contactFormular");
   }
    return (

    <>
    <NavigationComponent />
    <CarouselOverview/>
    <div className="container p-2">
            <div className="row">
                <div className="col-lg-4 col-md-12 mb-4 mx-auto">
                    <div className="card card1 h-100">
                        <div className="card-body  text-white">

                            <h5 className="card-title">Standard</h5>
                            <small className='text-muted'>Das Standard Paket ist Ideal für Statische Websiten</small>
                            <br/>
                            <span className="h2">ab €300</span>
                            
                            <br/>
                            <ul>
                                <li className="list">React mit Redux Anbindung </li>
                                <li className="list" >Impressum und Datenschutz konform </li>
                                  <li className="list" >Optional zubuchbar
                                  <ul>Mehrsprachigkeit</ul>
                                  <ul>Analytics</ul>
                                  </li>
                            </ul>

                            <div className="d-grid my-3">
                                
                                <button className="btn btn-success" onClick={()=> goToContactFormuularStandard()}>Website</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 mb-4 mx-auto">
                    <div className="card card2 h-100">
                        <div className="card-body text-dark ">

                            <h5 className="card-title">Deluxe</h5>
                            <small className='text-muted'>Das Deluxe Paket ist optimal für dynamisch Webseiten </small>
                            <br/>
                            <span className="h2">ab €500</span>
                            
                            <br/>
                            <ul>
                                <li>ASP.Net Dotnet 6.0  </li>
                                <li>Datenbankanbietung </li>
                                <li>React mit Redux Anbindung </li>
                                <li>Impressum und Datenschutz konform</li>
                                <li>Optional zubuchbar
                                    <ul>Mehrsprachigkeit</ul>
                                    <ul>Analytics</ul>
                                    <ul>gewünschte Api Anbindung</ul>
                                  </li>
                            </ul>
                            <br/>
                            <div className="d-grid my-3">
                                <button className="btn btn-success" onClick={()=>goToContactFormuularDeluxe() }>Website</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<div className="headline_main">
<h2 className="headline_Text-h1 ">Welche Webseitepakete umfassen Folgendes:</h2>
</div>
<div >
    <h3 className="featurette-heading line ">Standard - Paket</h3>
</div>


        <div className="body_sectioncenter">
    <div className="row featurette">
      <div className="col-md-7 order-md-1">
        

        <h2 className="featurette-heading fw-normal lh-1"> <b>Single Page Application Website</b></h2>
        <p className="lead">
        Eine Single Page Application (SPA) ist eine Webanwendung, 
        die aus einer einzigen HTML-Seite besteht und die Inhalte dynamisch und ohne 
        eine vollständige Aktualisierung der Seite lädt. SPAs sind in der Regel 
        schneller und interaktiver als traditionelle mehrseitige Webanwendungen.
        </p>
        <br/>
        
        <ul> <h3>Vorteile einer  Single Page Application Website:</h3>
        <li className="lead">eine bessere Benutzererfahrung</li>
        <li className="lead">weniger Serveranfragen</li>
        <li className="lead">bessere Wartbarkeit</li>
        <li className="lead">Reaktionsfähigkeit und einfache Entwicklung</li>

        </ul>
      </div>
      <div className="col-md-5 order-md-2">
        <img src={SinglePageApplicationPic} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"  alt="right_pic01"/>
      </div>
    </div>
  </div>

  <div >
    <h3 className="featurette-heading line">Deluxe  - Paket</h3>
</div>
  <div className="body_sectioncenter">
  <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1"><b>ASP.Net Web API</b></h2>
            <p className="lead">Das ASP.NET Core MVC-Framework ist ein einfaches, äußerst testfähiges Open-Source-Präsentationsframework, das für die Verwendung mit ASP.NET Core optimiert wurde. ASP.NET Core MVC stellt eine auf Mustern basierte Methode zum Entwickeln dynamischer Websites dar, die eine saubere Trennung von Belangen ermöglicht.
        Eine ASP.NET Core MVC Website bauen wir Backendseitig mit C#. Im Frontend gibt es die möglichkeit React zu benutzen. Die Auswahl der Frontendsprache kommt auf den Verwendungszweck drauf an
        Beispiel: Website mit Datenbank Zugriff, Mehrsprachigkeit</p>

        <br/>
        <ul> <h3>Vorteile einer  Asp.Net Web Api:</h3>
        <li className="lead">darunter klare Trennung von Verantwortlichkeiten</li>
        <li className="lead">Leistung und Skalierbarkeit</li>
        <li className="lead">Flexibilität, bessere Benutzererfahrung </li>
        <li className="lead">einfache Integration von Bibliotheken und Tools</li>

        </ul>
          </div>
          <div className="col-md-5 order-md-1">
            <img className="featurette-image img-fluid mx-auto"   src={Webapi} data-holder-rendered="true" width="500" height="500"  alt="left"/>
          </div>
        </div>
        </div>

  <div className="body_sectioncenter">
    <div className="row featurette">
      <div className="col-md-5">
        <h2 className="featurette-heading fw-normal lh-1"><b>Was bringen Sie mit</b></h2>
        <p className="lead">Viele Informationen über Ihre Unternehmen oder Dienstleistung </p>
         {/* <p className="lead">Wie z.B:</p> */}
        <p className="lead">
            <ul> <h3>Beispielsweise</h3>
                <li className="lead" >Texte der Ihre Dienstleistung beschreibt </li>
                <li className="lead">Bilder von Ihrem Unternehmen </li>
            </ul>
        </p>
      </div>
      <div className="col-md-7">
        <img src={mainlittle} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid rounded float-left" width="500" height="500"  alt="right_pic01"/>
      </div>
    </div>
  </div>

    <div className="body_sectioncenter">
  <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1"><b>Finden Sie den richtigen Hostinganbieter für Ihre Website</b></h2>
            <p className="lead">
            Ein Hostinganbieter stellt in der Regel Serverinfrastruktur und Netzwerkverbindungen zur Verfügung, um Websites und Anwendungen für Kunden im Internet zugänglich zu machen.
            </p>
            <p className="lead">
            Die Wahl des richtigen Hostinganbieters hängt von verschiedenen Faktoren ab, wie z.B. dem Umfang der Website oder Anwendung, der erwarteten Besucherzahl, dem Budget und den spezifischen Anforderungen der Anwendung. Es ist wichtig, einen zuverlässigen und erfahrenen Hostinganbieter zu wählen,
             um eine hohe Verfügbarkeit und Leistung der Website oder Anwendung zu gewährleisten.
            </p>
            
          </div>
          <div className="col-md-5 order-md-1">
            <img className="featurette-image img-fluid mx-auto"   src={FitnessStudioimages} data-holder-rendered="true" width="500" height="500"  alt="left"/>
          </div>

          
        </div>
       

        <div>
        <p className="lead">
            Wir empfehlen folgende Hostinganbieter
            </p>
        <div className="col-md-12">
                <div className="row mb-3">
                    <div className="col-sm-3 mx-auto">
                        <div className="card ">
                        <div className="card-body">
                            <h5 className="card-title">Ionos Dynamische Websiten</h5>
                            
                            <a href="https://www.ionos.de/hosting/windows-hosting?__lf=Order-Tariff-Webhosting" className="btn btn-primary">zur Website</a>
                        </div>
                        </div>
                    </div>
                    <div className="col-sm-3 mx-auto">
                        <div className="card ">
                        <div className="card-body">
                            <h5 className="card-title">Strato</h5>
                            
                            <a href="https://www.strato.de/hosting" className="btn btn-primary">zur Website</a>
                        </div>
                        </div>
                    </div>

                    <div className="col-sm-3 mx-auto">
                        <div className="card ">
                        <div className="card-body">
                            <h5 className="card-title">Ionos Statische Websiten</h5>
                           
                            <a href="https://www.ionos.de/hosting/deploy-now?__lf=Order-Tariff-Webhosting" className="btn btn-primary">zur Website</a>
                        </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
        </div>
    <Footeroverviewcomponent/>
    </>
      
    )
  }
  
  export default WebsiteOverviewComponent;

