
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../store/redux_store";
import { useAppDispatch } from "../../store/hook";
import { NavigationComponent } from "../../admin/Navigation/navigation.component";
import CarouselOverview from "../../admin/carousel/carouseloverviewComponent";
import Footeroverviewcomponent from "../../admin/footer/footeroverviewcomponent";
import Logo from "../../images/Logos.png";
import WhatIsAdvertising from "../../images/What is Advertings.png";
import { setOrderTyp, setOrderVarianteAdvertsingEnum } from "../../website/component/redux/contactslice";
import '../../website/styles/card_style.css';

export const AdvertsingOverviewComponent = () => {

    const navigate = useNavigate();
    const dispatch: AppDispatch = useAppDispatch();

    const gotoContactFormularFacebook = () => {
        dispatch(setOrderTyp(2));
        dispatch(setOrderVarianteAdvertsingEnum(1));
        navigate("/contactFormular");
    }

    const gotoContactFormularInstagram = () => {
        dispatch(setOrderTyp(2));
        dispatch(setOrderVarianteAdvertsingEnum(2));
        navigate("/contactFormular");
    }

    const gotoContactFormularGoogleAds = () => {
        dispatch(setOrderTyp(2));
        dispatch(setOrderVarianteAdvertsingEnum(3));
        navigate("/contactFormular");
    }

    return (

        <>
                <NavigationComponent />
                <CarouselOverview/>
                <div className="container p-2">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 mb-4 mx-auto">
                            <div className="card card1 h-100">
                                <div className="card-body bg-blue text-dark">

                                    <h5 className="card-title">Paket Google</h5>
                                    <small className='text-muted'>Google Ads</small>
                                    <br/>
                                    <span className="h2">€65</span>
                                    <br/>
                                    
                                    <ul>
                                        <li>Wir legen einen Account für Sie an </li>
                                        <li>Schalten Werbung für Sie </li>
                                    </ul>
                                    <br/>
                                    <div className="d-grid my-3">
                                        {/* <button className="btn btn-outline-light btn-block" onclick="location.href='@Url.Action("WebsiteSPA", "Website")'">Select</button> */}
                                    <button className="btn btn-success" onClick={() => gotoContactFormularGoogleAds()  } >Werbung</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mb-4 mx-auto">
                            <div className="card card2 h-100">
                                <div className="card-body bg-white text-dark ">

                                    <h5 className="card-title">Basic Facebook</h5>
                                    <small className='text-muted'>Facebook Ads </small>
                                    <br/>
                                    <span className="h2">€50</span>
                                    <br/>
                                    
                                    <ul>
                                    <li>Wir legen einen Account für Sie an </li>
                                        <li>Schalten Werbung für Sie </li>
                                    </ul>
                                    <br/>
                                    <div className="d-grid my-3">
                                        {/* <button className="btn btn-outline-dark btn-block" onclick="location.href='@Url.Action("WebsiteMVC", "Website")'">Select</button> */}
                                    <button className="btn btn-success" onClick={() => gotoContactFormularFacebook() }>Werbung</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mb-4 mx-auto">
                            <div className="card card2 h-100">
                                <div className="card-body bg-yellow text-dark ">

                                    <h5 className="card-title">Basic Instagram</h5>
                                    <small className='text-muted'>Instagram Ads </small>
                                    <br/>
                                    <span className="h2">€50</span>
                                    <br/>
                                    
                                    <ul>
                                    <li>Wir legen einen Account für Sie an </li>
                                        <li>Schalten Werbung für Sie </li>
                                    </ul>
                                    <br/>
                                    <div className="d-grid my-3">
                                        {/* <button className="btn btn-outline-dark btn-block" onclick="location.href='@Url.Action("WebsiteMVC", "Website")'">Select</button> */}
                                    <button className="btn btn-success" onClick={() => gotoContactFormularInstagram()}>Werbung</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="headline_main">
<h2 className="headline_Text-h1 ">Warum sollte ein Unternehmen Werbung schalten :</h2>
</div>

<div className="body_sectioncenter">
    <div className="row featurette">
      <div className="col-md-7 order-md-1">
        

        <h2 className="featurette-heading fw-normal lh-1"> <b>Werbung </b></h2>
        <p className="lead">
        Insgesamt kann Werbung dazu beitragen, das Wachstum und den Erfolg Ihres Unternehmens zu fördern, indem es die Bekanntheit Ihrer Marke steigert, den Absatz ankurbelt, Vertrauen aufbaut, einen Wettbewerbsvorteil bietet und Neuigkeiten verbreitet.
        </p>
        <br/>
        <p className="lead">
        Facebook und Instagram haben ein Online-Werbeprogramm namens Facebook Ads, während Google Ads ein Online-Werbeprogramm von Google ist. Mit diesen Programmen können Un-ternehmen Anzeigen schalten, die auf der jeweiligen Plattform sowie auf anderen Websites (in Google Ads) und in den Suchergebnissen (in beiden Programmen) erscheinen.
        </p>
      </div>
      <div className="col-md-5 order-md-2">
        <img src={WhatIsAdvertising} className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"  alt="right_pic01"/>
      </div>
    </div>
  </div>
  <div className="body_sectioncenter">
  <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading fw-normal lh-1"><b>Unterschied zwischen den Paketen</b></h2>
            <p className="lead">Es gibt einige Unterschiede in Bezug auf die Werbung auf den verschiedenen Social-Media-Plattformen wie Instagram, Google und Facebook</p>
            <br/>
            <ul className="underline"> <h3>Hier sind einige wichtige Unterschiede:</h3>
                <li className="lead">Zielgruppen: Jede Plattform hat ihre eigene Zielgruppe. Während Facebook und Insta-gram insbesondere bei jüngeren Zielgruppen beliebt sind, ist Google eher eine Suchma-schine und zieht ein breiteres Publikum an. Unternehmen sollten die Zielgruppe be-rücksichtigen, wenn sie sich für die Schaltung von Anzeigen auf einer Plattform ent-scheiden.</li>
                <li className="lead">Anzeigenformate: Jede Plattform bietet unterschiedliche Anzeigenformate, von Text-anzeigen über Bild- und Videoanzeigen bis hin zu Karussell-Anzeigen. Unternehmen sollten die verfügbaren Anzeigenformate auf jeder Plattform prüfen und entscheiden, welche am besten zu ihrer Marketingstrategie passt.</li>
                <li className="lead">Anzeigenplatzierung: Die Platzierung von Anzeigen auf jeder Plattform unterscheidet sich ebenfalls. Google Ads werden in der Regel in den Suchergebnissen oder auf an-deren Websites geschaltet, während Facebook und Instagram Anzeigen auf der Platt-form selbst schalten. Unternehmen sollten entscheiden, wo sie ihre Anzeigen platzieren möchten, um die beste Wirkung zu erzielen.</li>
                <li className="lead">Kosten: Die Kosten für Werbung auf jeder Plattform unterscheiden sich ebenfalls. Google Ads basieren auf dem CPC (Cost per Click), während Facebook und Insta-gram ihre Kosten auf CPM (Cost per Thousand Impressions) und CPC basieren. Un-ternehmen sollten die Kosten für jede Plattform sorgfältig prüfen und sicherstellen, dass sie in ihr Budget passen.</li>
                <li className="lead">Tracking und Analyse: Jede Plattform bietet unterschiedliche Tracking- und Analyse-funktionen für Anzeigen. Unternehmen sollten sich mit den verfügbaren Tools vertraut machen, um ihre Anzeigenleistung zu verfolgen und zu optimieren.</li>
            </ul>
            <br/>
            <p className="lead">Insgesamt gibt es Unterschiede in Bezug auf Zielgruppen, Anzeigenformate, Platzierung, Kos-ten und Tracking-Tools zwischen den verschiedenen Social-Media-Plattformen. Unternehmen sollten diese Faktoren sorgfältig abwägen, um zu entscheiden, welche Plattform am besten zu ihrer Marketingstrategie passt.</p>
          </div>
          <div className="col-md-5 order-md-1">
            <img className="featurette-image img-fluid mx-auto"   src={Logo} data-holder-rendered="true" width="500" height="500"  alt="left"/>
          </div>
        </div>
        </div>           
        <Footeroverviewcomponent/>
        </>
      
    )
  }
  
  export default AdvertsingOverviewComponent;